<template>
  <section id="sub-status" class="sub-status" :key="subStatusKey">
    <div class="demo-inline-spacing">
      <b-badge
        v-for="status in subStatusItems"
        :key="status.key"
        v-show="enableStatusItems.includes(status.key)"
        :variant="arrStatusChoosed.includes(status.key) ? status.variant : 'light-' + status.variant" class="sub-status-item"
        :class="arrStatusChoosed.includes(status.key) ? 'badge-glow' : ''"
        @click="chooseSubStatus(status.key)"
      >
        {{ status.label }}
      </b-badge>
    </div>
  </section>
</template>

<script>
import {
  BBadge
} from 'bootstrap-vue'
import { ref, watch } from "@vue/composition-api";

export default {
  components: {
    BBadge
  },
  props: {
    enableStatusItems: {
      type: Array,
      required: true,
    },
    multipleChoice: {
      type: Boolean,
      required: true,
    },
    statusChoosed: {
      type: Array,
      required: true,
    },
    statusValue: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const vm = context.root
    const subStatusKey = ref(vm.generateSyncId())
    const subStatusItems = ref([
      {key: 'all', label: vm.$i18n.t('StatusLabel.All'), variant: 'primary'},
      {key: 'approach', label: vm.$i18n.t('StatusLabel.Approach'), variant: 'info'},
      {key: 'potential', label: vm.$i18n.t('StatusLabel.Potential'), variant: 'success'},
      {key: 'do_not_forget', label: vm.$i18n.t('StatusLabel.Do Not Forget'), variant: 'danger'},
      {key: 'call_back', label: vm.$i18n.t('StatusLabel.Call Back'), variant: 'warning'},
      {key: 'not_enough_info', label: vm.$i18n.t('StatusLabel.Not enough information'), variant: 'secondary'},
      {key: 'other', label: vm.$i18n.t('StatusLabel.Other'), variant: 'dark'},
    ])
    const arrStatusChoosed = ref(props.statusValue)
    const chooseSubStatus = (status) => {
      if(props.multipleChoice) {
        if(status == 'all') {
          arrStatusChoosed.value = [status]
        } else {
          arrStatusChoosed.value = vm._.xor(arrStatusChoosed.value, [status])
          arrStatusChoosed.value = vm._.remove(arrStatusChoosed.value, function(n) {
            return n != 'all';
          })
          if(arrStatusChoosed.value.length == 0) {
            arrStatusChoosed.value = ['all']
          }
        }
      } else {
        arrStatusChoosed.value = vm._.xor(arrStatusChoosed.value, [status])
        arrStatusChoosed.value = arrStatusChoosed.value.length > 0 ? [vm._.last(arrStatusChoosed.value)] : []
      }
      context.emit("update:status-choosed", arrStatusChoosed.value);
    }
    watch(() => props.statusValue, () => {
      subStatusKey.value = vm.generateSyncId()
      arrStatusChoosed.value = props.statusValue
    });
    return {
      subStatusKey,
      subStatusItems,
      chooseSubStatus,
      arrStatusChoosed,
    }
  },
}
</script>

<style lang="scss">