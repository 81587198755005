<template>
  <b-row>
    <b-col lg="7">
      <b-card class="mb-0">
        <b-card-header class="pt-0 pl-0 pr-0">
          <b-card-title>{{
            $t(tabChoosed) +
              " - " +
              $t(objectTaskDetailsData.customer.type)
          }}</b-card-title>
          <div>
            <b-button
              variant="primary"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon mr-1"
              @click="isAddCustomerNoteCollapseActive = !isAddCustomerNoteCollapseActive"
              v-if="taskDetailsTabActive == 'notes'"
            >
              <feather-icon icon="MessageCircleIcon" size="15" />
            </b-button>
            <b-button
              variant="outline-primary"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              class="btn-icon"
              @click="backToTaskList"
              :disabled="isCalling"
            >
              <feather-icon icon="SkipBackIcon" size="15" />
            </b-button>
          </div>
        </b-card-header>
        <b-card-text>
          <b-tabs>
            <b-tab :title="$t('Modules.Task.Label.Information')" :active="taskDetailsTabActive == 'information'" @click="taskDetailsTabActive='information'">
              <b-row>
                <b-col lg="12">
                  <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                  >
                    <!-- Form -->
                    <b-form
                      @submit.prevent="handleSubmit(onSubmit)"
                      @reset.prevent="resetForm"
                      v-on:change="handleSubmit(onChange)"
                    >
                    <b-row>
                        <b-col xs="12" lg="6">
                          <!-- Customer Name -->
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Modules.Customer.Label.Customer name')"
                            :rules="
                              (objectTaskDetailsData.customerPhone != '' &&
                                objectTaskDetailsData.customerPhone != null) ||
                              (objectTaskDetailsData.customerGender != '' &&
                                objectTaskDetailsData.customerGender != null) ||
                              (objectTaskDetailsData.customerState != '' &&
                                objectTaskDetailsData.customerState != null)
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-group
                              :label="
                                $t('Modules.Customer.Label.Customer name')
                              "
                              label-for="customer_name"
                            >
                              <b-form-input
                                id="customer_name"
                                v-model="objectTaskDetailsData.customerName"
                                :state="getValidationState(validationContext)"
                                trim
                                autocapitalize="off"
                                autocomplete="new-customer-name"
                                autocorrect="off"
                                spellcheck="false"
                                :disabled="objectTaskDetailsData.customer.type == 'work_phone' ? !isCalling : false "
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col xs="12" lg="6">
                          <!-- Cell Phone Number -->
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Modules.Customer.Label.Phone number')"
                            :rules="
                              ((objectTaskDetailsData.customerName != '' &&
                                objectTaskDetailsData.customerName != null) ||
                              (objectTaskDetailsData.customerGender != '' &&
                                objectTaskDetailsData.customerGender != null) ||
                              (objectTaskDetailsData.customerState != '' &&
                                objectTaskDetailsData.customerState != null)
                                ? 'required|'
                                : '') +
                                'regex:^[+]1 [(][0-9]{3}[)] [0-9]{3}[-][0-9]{4}]*$'
                            "
                          >
                            <b-form-group
                              :label="$t('Modules.Customer.Label.Phone number')"
                              label-for="customer_phone"
                            >
                              <b-form-input
                                id="customer_phone"
                                v-model="objectTaskDetailsData.customerPhone"
                                :state="getValidationState(validationContext) && customerPhoneValidateStatus"
                                trim
                                autocapitalize="off"
                                autocomplete="new-customer-phone"
                                autocorrect="off"
                                spellcheck="false"
                                v-mask="'+1 (###) ###-####'"
                                @change="autoSelectState(getValidationState(validationContext), objectTaskDetailsData.customerPhone)"
                                @keyup="autoSelectState(getValidationState(validationContext), objectTaskDetailsData.customerPhone)"
                                :disabled="objectTaskDetailsData.customer.type == 'work_phone' ? !isCalling : '' "
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col xs="12" lg="6">
                          <!-- State -->
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Modules.Customer.Label.State')"
                            :rules="
                              ((objectTaskDetailsData.customerName != '' &&
                                objectTaskDetailsData.customerName != null) ||
                              (objectTaskDetailsData.customerGender != '' &&
                                objectTaskDetailsData.customerGender != null) ||
                              (objectTaskDetailsData.customerPhone != '' &&
                                objectTaskDetailsData.customerPhone != null)
                                ? 'required|'
                                : '')
                            "
                          >
                            <b-form-group
                              :label="$t('Modules.Customer.Label.State')"
                              label-for="customer_state"
                              :state="getValidationState(validationContext)"
                            >
                              <v-select
                                v-model="objectTaskDetailsData.customerState"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="stateOptions"
                                :reduce="val => val.value"
                                :clearable="false"
                                input-id="customer_state"
                                autocomplete="new-customer-state"
                                :state="getValidationState(validationContext)"
                                :disabled="objectTaskDetailsData.customer.type == 'work_phone' ? !isCalling : false "
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col xs="12" lg="4">
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Modules.Customer.Label.Gender')"
                            :rules="
                              ((objectTaskDetailsData.customerName != '' &&
                                objectTaskDetailsData.customerName != null) ||
                              (objectTaskDetailsData.customerState != '' &&
                                objectTaskDetailsData.customerState != null) ||
                              (objectTaskDetailsData.customerPhone != '' &&
                                objectTaskDetailsData.customerPhone != null)
                                ? 'required|'
                                : '')
                            "
                          >
                            <b-form-group
                              :label="$t('Modules.Customer.Label.Gender')"
                              label-for="gender"
                              :state="getValidationState(validationContext)"
                            >
                              <div class="demo-inline-spacing">
                                <b-form-radio
                                  v-model="objectTaskDetailsData.customerGender"
                                  name="female"
                                  value="female"
                                  class="custom-control-primary mar-top-10"
                                  :state="getValidationState(validationContext)"
                                  :disabled="objectTaskDetailsData.customer.type == 'work_phone' ? !isCalling : false "
                                >
                                  {{ $t("Female") }}
                                </b-form-radio>
                                <b-form-radio
                                  v-model="objectTaskDetailsData.customerGender"
                                  name="male"
                                  value="male"
                                  class="custom-control-primary mar-top-10"
                                  :state="getValidationState(validationContext)"
                                  :disabled="objectTaskDetailsData.customer.type == 'work_phone' ? !isCalling : false "
                                >
                                  {{ $t("Male") }}
                                </b-form-radio>
                              </div>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col xs="12" lg="2">
                          <!-- Shop Owner -->
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Modules.Customer.Label.Shop owner')"
                          >
                            <b-form-group
                              label-for="shop_owner"
                              :state="getValidationState(validationContext)"
                              :label="$t('Modules.Customer.Label.Shop owner')"
                            >
                              <div class="mt-1">
                                <b-form-checkbox
                                  v-model="objectTaskDetailsData.shopOwner"
                                  value="true"
                                  :disabled="objectTaskDetailsData.customer.type == 'work_phone' ? !isCalling : false "
                                >
                              </b-form-checkbox>
                              </div>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col xs="12" lg="6">
                          <!-- Work Place Name -->
                          <validation-provider
                            #default="validationContext"
                            :label="$t('Modules.Customer.Label.Shop name')"
                            :rules="objectTaskDetailsData.customer.type == 'work_phone' ? 'required' : ( objectTaskDetailsData.workPlacePhone ? 'required' : '' )"
                          >
                            <b-form-group
                              :label="$t('Modules.Customer.Label.Shop name')"
                              label-for="work_place_name"
                            >
                              <b-form-input
                                id="work_place_name"
                                v-model="objectTaskDetailsData.workPlaceName"
                                :state="getValidationState(validationContext)"
                                trim
                                autocapitalize="off"
                                autocomplete="new-work-place-name"
                                autocorrect="off"
                                spellcheck="false"
                                maxlength="100"
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col xs="12" lg="6">
                          <!-- Work Place Phone -->
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Modules.Customer.Label.Work phone')"
                            :rules="(objectTaskDetailsData.workPlaceName ? 'required|' : '') + 'regex:^[+]1 [(][0-9]{3}[)] [0-9]{3}[-][0-9]{4}]*$'"
                          >
                            <b-form-group
                              :label="$t('Modules.Customer.Label.Work phone')"
                              label-for="work_place_phone"
                            >
                              <b-form-input
                                id="work_place_phone"
                                v-model="objectTaskDetailsData.workPlacePhone"
                                :state="getValidationState(validationContext) && workPlacePhoneValidateStatus"
                                trim
                                autocapitalize="off"
                                autocomplete="new-work-place-phone"
                                autocorrect="off"
                                spellcheck="false"
                                v-mask="'+1 (###) ###-####'"
                                :disabled="objectTaskDetailsData.customer.type == 'work_phone'"
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col xs="12" lg="6">
                          <!-- Status -->
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Modules.Customer.Label.Status')"
                          >
                            <b-form-group
                              :label="$t('Modules.Customer.Label.Status')"
                              label-for="customer_status"
                              :state="getValidationState(validationContext)"
                            >
                              <v-select
                                v-model="objectTaskDetailsData.customerStatus"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="phoneStatusOptions"
                                :reduce="val => val.value"
                                input-id="customer_status"
                                autocomplete="new-customer-status"
                                @input="saveButtonStatus = true"
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col xs="12" lg="6">
                          <!-- Customer Email -->
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Modules.Customer.Label.Email')"
                            rules="email"
                          >
                            <b-form-group
                              :label="$t('Modules.Customer.Label.Email')"
                              label-for="email"
                            >
                              <b-form-input
                                id="email"
                                v-model="objectTaskDetailsData.email"
                                :state="getValidationState(validationContext)"
                                trim
                                autocapitalize="off"
                                autocomplete="new-email"
                                autocorrect="off"
                                spellcheck="false"
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col xs="12" lg="12">
                          <sub-status
                            :enable-status-items="tabChoosed == 'potential' ? potentialSubStatusItems : hotHitSubStatusItems"
                            :status-choosed.sync="subStatusChoosed"
                            :status-value.sync="subStatusValue"
                            :multiple-choice=false
                            v-if="objectTaskDetailsData.customer.type == 'cell_phone' && tabChoosed != 'denied' && tabChoosed != 'closed_case'"
                          />
                        </b-col>
                      </b-row>
                      <div class="d-flex mt-2 justify-content-sm-end">
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          type="reset"
                          class="mr-2"
                          variant="outline-secondary"
                        >
                          {{ $t("Cancel") }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :variant="saveButtonStatus ? 'primary' : 'outline-secondary'"
                          type="button"
                          @click="handleSubmit(onSave)"
                        >
                          {{ $t("Save") }}
                        </b-button>
                      </div>
                      <!-- Form Actions -->
                    </b-form>
                  </validation-observer>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab :title="$t('Modules.Task.Label.Notes')" :active="taskDetailsTabActive == 'notes'" @click="taskDetailsTabActive='notes'">
              <b-collapse id="collapse-add-customer-note" v-model="isAddCustomerNoteCollapseActive" class="mt-2">
                <add-customer-note
                  :is-add-customer-note-collapse-active.sync="isAddCustomerNoteCollapseActive"
                  :customer-id.sync="objectTaskDetailsData.customer.id"
                  @update-customer-note="updateCustomerNote"
                />
              </b-collapse>
              <b-table
                class="position-relative"
                :items="objectTaskDetailsData.notes"
                :fields="noteColumns"
                :sticky-header="isAddCustomerNoteCollapseActive ? '400px' : '625px'"
                hover
                show-empty
                :empty-text="$t('No matching records found')"
              >
                <!-- Column: Created At -->
                <template #cell(createdAt)="data">
                  <div class="text-nowrap" v-html="formatTimeZone(data.item.createdAt)">
                  </div>
                </template>

                <!-- Column: Status -->
                <template #cell(note)="data">
                  <div class="text">
                    <span v-html="data.item.note"></span>
                  </div>
                </template>

              </b-table>
            </b-tab>
            <b-tab :title="$t('Modules.Task.Label.Call History')" :active="taskDetailsTabActive == 'callHistories'" @click="taskDetailsTabActive='callHistories'">
              <b-table
                class="position-relative"
                :items="objectTaskDetailsData.callHistories"
                :fields="callHistoryColumns"
                sticky-header="625px"
                hover
                show-empty
                :empty-text="$t('No matching records found')"
              >
                <!-- Column: Created At -->
                <template #cell(createdAt)="data">
                  <div class="text-nowrap" v-html="formatTimeZone(data.item.createdAt)">
                  </div>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                  <div class="text-nowrap" style="min-width: 60px;">
                    <b-badge :variant="getCallStatusVariant(data.item.status)">{{ $t(data.item.status) }}</b-badge>
                  </div>
                </template>

                <!-- Column: Status -->
                <template #cell(note)="data">
                  <div class="text">
                    <span v-html="data.item.note"></span>
                  </div>
                </template>

              </b-table>
            </b-tab>
          </b-tabs>
        </b-card-text>
      </b-card>
    </b-col>
    <b-col lg="5">
      <b-card class="dial-card">
        <b-card-text>
          <div class="inline-spacing">
            <b-row class="status-section mt-2">
              <b-col lg="12">
                <h2 class="text-center text-primary">
                  {{
                    (objectTaskDetailsData.customer.type == "cell_phone"
                      ? objectTaskDetailsData.customerGender
                        ? $t(
                            "Modules.Customer.Label." +
                              objectTaskDetailsData.customerGender
                          )
                        : ""
                      : "") + (
                        objectTaskDetailsData.customer.type == "cell_phone" ? objectTaskDetailsData.customerName : objectTaskDetailsData.workPlaceName
                      )
                  }}
                </h2>
                <h4 class="text-center" v-if="objectTaskDetailsData.customer.workplace">
                  <span v-if="switchPhoneType == 'cell_phone'">
                    <span class="font-small-4 mr-1">{{ $t('Modules.Customer.Label.Phone number') + ':' }}</span>
                    <span class="mr-1">{{ objectTaskDetailsData.customerPhone }}</span>
                    <b-button
                      variant="flat-primary"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      class="btn-icon btn-square mar-0"
                      @click="switchPhoneType='work_phone'"
                      :disabled="isCalling"
                      >
                        <feather-icon icon="RepeatIcon" size="15" />
                      </b-button>
                  </span>
                  <span v-else>
                    <span class="font-small-4 mr-1">{{ $t('Modules.Customer.Label.Work phone') + ':' }}</span>
                    <span class="mr-1">{{ objectTaskDetailsData.workPlacePhone }}</span>
                    <b-button
                      variant="flat-primary"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      class="btn-icon btn-square mar-0"
                      @click="switchPhoneType='cell_phone'"
                      :disabled="isCalling"
                    >
                      <feather-icon icon="RepeatIcon" size="15" />
                    </b-button>
                  </span>
                </h4>
                <h4 class="text-center" v-else>
                  {{ objectTaskDetailsData.customer.type == "cell_phone" ? objectTaskDetailsData.customerPhone : objectTaskDetailsData.workPlacePhone }}
                </h4>
              </b-col>
              <b-col lg="6 mt-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      {{ customerLocale.stateCode }}
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 text-success">
                      {{ customerLocale.time }}
                    </h4>
                    <b-card-text
                      class="font-small-3 mb-0 white-space-nowrap text-success"
                    >
                      {{ customerLocale.stateName }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col lg="6 mt-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-info">
                      {{ currentLocale.stateCode }}
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 text-info">
                      {{ currentLocale.time }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0 text-info">
                      {{ currentLocale.stateName }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col lg="12 text-center mt-1"> </b-col>
              <b-col lg="3">
                <b-button
                  variant="outline-primary"
                  class="btn-icon"
                  block
                  @click="choosePrevItem"
                  :disabled="isCalling"
                >
                  <feather-icon icon="ChevronsLeftIcon" size="25" />
                </b-button>
              </b-col>
              <b-col lg="6">
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  block
                  @click="handleCall"
                  v-if="!isCalling"
                >
                  <feather-icon icon="PhoneIcon" size="25" />
                </b-button>
                <b-button
                  variant="outline-success"
                  class="btn-icon pad-top-13 pad-bot-13"
                  block
                  v-if="isCalling"
                >
                  <b-spinner
                    type="grow"
                    variant="success"
                    class="mr-1 ml-1"
                    small
                  />
                  <b-spinner
                    type="grow"
                    variant="success"
                    class="mr-1 ml-1"
                    small
                  />
                  <b-spinner
                    type="grow"
                    variant="success"
                    class="mr-1 ml-1"
                    small
                  />
                </b-button>
              </b-col>
              <b-col lg="3">
                <b-button
                  variant="outline-primary"
                  class="btn-icon"
                  block
                  @click="chooseNextItem"
                  :disabled="isCalling"
                >
                  <feather-icon icon="ChevronsRightIcon" size="25" />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card-text>
      </b-card>
      <b-card class="dial-card" v-if="isCalling">
        <b-card-text>
          <div class="inline-spacing">
            <b-row class="status-section mt-2">
              <b-col lg="12">
                <div class="form-label-group">
                  <quill-editor
                    v-model="callResult.note"
                    :options="editorOption"
                    style="height: 245px;"
                  />
                  <!-- <b-form-textarea
                    id="call_result_note"
                    rows="3"
                    :placeholder="$t('note')"
                    v-model="callResult.note"
                    class="call-result-note"
                  /> -->
                  <label for="label-textarea">{{ $t("note") }}</label>
                </div>
              </b-col>
              <b-col lg="4">
                <b-button
                  :variant="
                    callResultStatus == 'no_answer'
                      ? 'primary'
                      : 'outline-primary'
                  "
                  block
                  @click="chooseStatus('no_answer')"
                >
                  NO<br />ANSWER
                </b-button>
              </b-col>
              <b-col lg="4" class="dis-flex">
                <b-button
                  class="mar-rig-0"
                  :variant="
                    callResultStatus == 'answer' ? 'success' : 'outline-success'
                  "
                  block
                  @click="chooseStatus('answer')"
                >
                  ANSWER
                </b-button>
              </b-col>
              <b-col lg="4">
                <b-button
                  :variant="
                    callResultStatus == 'hang_up' ? 'info' : 'outline-info'
                  "
                  block
                  @click="chooseStatus('hang_up')"
                >
                  HANG<br />UP
                </b-button>
              </b-col>
              <b-col lg="4">
                <b-button
                  :variant="
                    callResultStatus == 'wrong_number'
                      ? 'danger'
                      : 'outline-danger'
                  "
                  block
                  @click="chooseStatus('wrong_number')"
                >
                  WRONG<br />NUMBER
                </b-button>
              </b-col>
              <b-col lg="4" class="dis-flex">
                <b-button
                  :variant="
                    callResultStatus == 'busy' ? 'warning' : 'outline-warning'
                  "
                  class="mar-rig-0"
                  block
                  @click="chooseStatus('busy')"
                >
                  BUSY
                </b-button>
              </b-col>
              <b-col lg="4">
                <b-button
                  :variant="
                    callResultStatus == 'no_need' ? 'dark' : 'outline-dark'
                  "
                  block
                  @click="chooseStatus('no_need')"
                >
                  NO<br />NEED
                </b-button>
              </b-col>
              <b-col lg="12">
                <b-button
                  variant="gradient-danger"
                  class="btn-icon"
                  block
                  @click="handleEndCall"
                  :disabled="callResultStatus == null"
                >
                  <feather-icon icon="PhoneOffIcon" size="25" />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BTabs,
  BTab,
  BTable,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BSpinner,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormTextarea,
  BFormRadio,
  BBadge,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import { ref, watch } from "@vue/composition-api";
import store from "@/store";
import { mainDB } from "@/database/mainDB";
import { userDB } from "@/database/userDB";
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import addCustomerNote from "./addCustomerNote.vue";
import subStatus from "./subStatus.vue";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BTabs,
    BTab,
    BTable,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormTextarea,
    BFormRadio,
    BBadge,
    BCollapse,
    VBToggle,
    vSelect,
    quillEditor,
    addCustomerNote,
    subStatus,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    taskDetailsData: {
      type: Object,
      required: true,
    },
    taskDetailsDataOrigin: {
      type: Object,
      required: true,
    },
    stateOptions: {
      type: Array,
      required: true,
    },
    isShowDetails: {
      type: Boolean,
      required: true,
    },
    tabChoosed: {
      type: String,
      required: true,
    },
    indexChoosed: {
      type: Number,
    },
    coldCallSortBy: {
      type: String,
    },
    coldCallIsSortDirDesc: {
      type: Boolean,
    },
    isCalling: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, context) {
    const vm = context.root;
    const taskSession = store.state.task;
    //const isCalling = ref(taskSession.isCalling);
    context.emit("update:is-calling", taskSession.isCalling);
    const callResult = ref(taskSession.callResult);
    const callResultStatus = ref(null);
    const taskDetailsTabActive = ref('information')
    const objectTaskDetailsData = ref(props.taskDetailsData)
    const customerPhoneValidateStatus = ref(true)
    const workPlacePhoneValidateStatus = ref(true)
    const editorOption = ref({
      modules: {
        toolbar: false,
      },
      placeholder: vm.$i18n.t('note') + '...',
    })
    const saveButtonStatus = ref(false)
    const switchPhoneType = ref('cell_phone')
    const isAddCustomerNoteCollapseActive = ref(false)

    const noteColumns = [
      { key: "createdAt", sortable: false, label: vm.$i18n.t('Create At') },
      { key: "note", sortable: false, label: vm.$i18n.t('note') },
      { key: "author", sortable: false, label: vm.$i18n.t('Author') },
    ];

    const callHistoryColumns = [
      { key: "createdAt", sortable: false, label: vm.$i18n.t('Create At') },
      { key: "status", sortable: false, label: vm.$i18n.t('Result') },
      { key: "author", sortable: false, label: vm.$i18n.t('Author') },
    ];

    const phoneStatusOptions = [
      { label: vm.$i18n.t("potential"), value: "potential" },
      { label: vm.$i18n.t("hothit"), value: "hothit" },
      { label: vm.$i18n.t("denied"), value: "denied" },
      { label: vm.$i18n.t("closed_case"), value: "closed_case" },
    ];

    const potentialSubStatusItems = ['approach', 'potential', 'do_not_forget']
    const hotHitSubStatusItems = ['potential', 'do_not_forget']
    const subStatusChoosed = ref([])
    const subStatusValue = ref([objectTaskDetailsData.value.subStatus])

    /******************************** */
    /** Time */
    /******************************** */
    var currentTimeInterval = null
    const currentLocale = ref({
      timeZone: '',
      stateCode: '..',
      stateName: '...',
      time: '00:00 AM'
    });
    const customerLocale = ref({
      timeZone: '',
      stateCode: '..',
      stateName: '...',
      time: '00:00 AM'
    });

    const getCurrentTime = (item) => {
      currentLocale.value = {
        timeZone: 'America/New_York',
        stateCode: 'GA',
        stateName: 'Georgia',
        time: vm.getCurrentTimeByTimeZone('America/New_York')
      }
      customerLocale.value = {
        timeZone: item.customer.state.timeZone,
        stateCode: item.customer.state.stateCode,
        stateName: item.customer.state.stateName,
        time: vm.getCurrentTimeByTimeZone(item.customer.state.timeZone)
      }
    }

    const loadCurrentTime = (item) => {
      currentTimeInterval = setInterval(() => {
        getCurrentTime(item)
      }, 1000)
    }

    const clearCurrentTime = () => {
      if(typeof(currentTimeInterval != 'undefined')) clearInterval(currentTimeInterval)
      currentLocale.value = {
        timeZone: '',
        stateCode: '..',
        stateName: '...',
        time: '00:00 AM'
      }
      customerLocale.value = {
        timeZone: '',
        stateCode: '..',
        stateName: '...',
        time: '00:00 AM'
      }
    }

    watch(() => props.isShowDetails, () => {
      if(props.isShowDetails) {
        getCurrentTime(objectTaskDetailsData.value)
        if(typeof(currentTimeInterval != 'undefined')) clearInterval(currentTimeInterval)
        loadCurrentTime(objectTaskDetailsData.value)
      } else {
        clearCurrentTime()
      }
    }, { immediate: true });

    watch(() => props.taskDetailsDataOrigin, () => {
      objectTaskDetailsData.value = JSON.parse(JSON.stringify(props.taskDetailsDataOrigin))
      getCurrentTime(objectTaskDetailsData.value)
      if(typeof(currentTimeInterval != 'undefined')) clearInterval(currentTimeInterval)
      loadCurrentTime(objectTaskDetailsData.value)
      subStatusValue.value = [objectTaskDetailsData.value.subStatus]
    }, { immediate: true });

    watch(subStatusChoosed, () => {
      switch (props.tabChoosed) {
        case "potential":
          userDB.potentialSubStatus.put({
            id: objectTaskDetailsData.value.id,
            syncId: vm.generateSyncId(),
            subStatus: subStatusChoosed.value[0] ?? ''
          })
          userDB.potentials.update(objectTaskDetailsData.value.id, {subStatus: subStatusChoosed.value[0] ?? ''});
          break;
        case "hothit":
          userDB.hotHitSubStatus.put({
            id: objectTaskDetailsData.value.id,
            syncId: vm.generateSyncId(),
            subStatus: subStatusChoosed.value[0] ?? ''
          })
          userDB.hotHits.update(objectTaskDetailsData.value.id, {subStatus: subStatusChoosed.value[0] ?? ''});
          break;
      }
      vm.autoSaveLoading();
    });
    /******************************** */

    const resetTaskDetails = () => {
      objectTaskDetailsData.value = JSON.parse(JSON.stringify(props.taskDetailsDataOrigin))
      saveButtonStatus.value = false
      // context.emit(
      //   "update:task-details-data",
      //   JSON.parse(JSON.stringify(props.taskDetailsDataOrigin))
      // );
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetTaskDetails
    );
    const onChange = () => {
      saveButtonStatus.value = true
      onSubmit()
    }
    const onSave = () => {
      saveButtonStatus.value = false
      onSubmit(true)
    }
    const onSubmit = (syncStatus) => {
      if (objectTaskDetailsData.value.customer.type == "work_phone") {
        // Check update customer information
        if (
          objectTaskDetailsData.value.workPlaceName != props.taskDetailsDataOrigin.workPlaceName
          || objectTaskDetailsData.value.email != props.taskDetailsDataOrigin.email
          || objectTaskDetailsData.value.customerStatus != props.taskDetailsDataOrigin.customerStatus
        ) {
          updateCustomer(syncStatus);
        }
        // Check add potential customer information
        if (
          objectTaskDetailsData.value.customerName
          && objectTaskDetailsData.value.customerState
          && objectTaskDetailsData.value.customerPhone
          && objectTaskDetailsData.value.customerGender
        ) {
          addCustomer();
        }
      } else {
        // Check update customer information
        if (
          objectTaskDetailsData.value.customerName != props.taskDetailsDataOrigin.customerName
          || objectTaskDetailsData.value.email != props.taskDetailsDataOrigin.email
          || objectTaskDetailsData.value.customerGender != props.taskDetailsDataOrigin.customerGender
          || objectTaskDetailsData.value.shopOwner != props.taskDetailsDataOrigin.shopOwner
          || objectTaskDetailsData.value.customerStatus != props.taskDetailsDataOrigin.customerStatus
          || objectTaskDetailsData.value.customerState != props.taskDetailsDataOrigin.customerState
          || objectTaskDetailsData.value.workPlaceName != props.taskDetailsDataOrigin.workPlaceName
          || objectTaskDetailsData.value.workPlacePhone != props.taskDetailsDataOrigin.workPlacePhone
        ) {
          updateCustomer(syncStatus);
        }
      }
      vm.autoSaveLoading();
    };
    const handleCall = () => {
      context.emit("update:is-calling", true)
      taskSession.isCalling = true;
      callResult.value = {
        id: objectTaskDetailsData.value.id,
        createdAt: Math.floor(Date.now() / 1000),
        status: null,
        note: null,
      };
      taskSession.callResult = callResult.value;
      store.dispatch("task/update", taskSession);
      if(switchPhoneType.value == 'cell_phone')
        window.location.href = 'callto:' + objectTaskDetailsData.value.phoneNumber;
      else
        window.location.href = 'callto:' + objectTaskDetailsData.value.workPlacePhone;
    };
    const handleEndCall = () => {
      callResultStatus.value = null;
      userDB.callResults.put({
        id: objectTaskDetailsData.value.customer.id,
        taskId: objectTaskDetailsData.value.id,
        syncId: vm.generateSyncId(),
        name: objectTaskDetailsData.value.name,
        phoneNumber: objectTaskDetailsData.value.phoneNumber,
        customerPhone: objectTaskDetailsData.value.customerPhone,
        stateLabel: objectTaskDetailsData.value.stateLabel,
        createdAt: callResult.value.createdAt,
        status: callResult.value.status,
        note: callResult.value.note,
        type: props.tabChoosed,
        isKeepData: objectTaskDetailsData.value.isKeepData
      });
      taskDetailsTabActive.value = 'information'
      updateCustomerCallHisttory(callResult.value.status)
      updateCustomerNote({customerId: objectTaskDetailsData.value.customer.id, customerNote: callResult.value.note, newPhoneNumber: objectTaskDetailsData.value.customerPhone})
      switch (props.tabChoosed) {
        case "cold_call":
          context.emit("load-details-by-index-choosed");
          break;
        case "potential":
          backToTaskList()
          break;
        case "hothit":
          backToTaskList()
          break;
        case "denied":
          backToTaskList()
          break;
        case "closed_case":
          backToTaskList()
          break;
        case "assistant":
          break;
        case "call_log":
          break;
      }
      taskSession.taskDetailsData = {}
      taskSession.isCalling = false;
      store.dispatch("task/update", taskSession);
    };
    const chooseNextItem = () => {
      context.emit("update:index-choosed", props.indexChoosed + 1);
      taskDetailsTabActive.value = 'information'
      context.emit("load-details-by-index-choosed");
    };
    const choosePrevItem = () => {
      context.emit("update:index-choosed", props.indexChoosed - 1);
      taskDetailsTabActive.value = 'information'
      context.emit("load-details-by-index-choosed");
    };
    const chooseStatus = async (status) => {
      if(status == 'wrong_number') {
        if (await vm.showDeleteConfirmDialog(vm.$i18n.t('message.Do you want to continue?'))) {
          callResultStatus.value = status;
          callResult.value.status = status;
        }
      } else {
        callResultStatus.value = status;
        callResult.value.status = status;
      }
    };
    const addCustomer = async (syncStatus) => {
      const customerExistsInPotential = await mainDB.potentialFollowingCustomers.get({phoneNumber: objectTaskDetailsData.value.customerPhone})
      const customerExistsInHotHit = await mainDB.hotHitFollowingCustomers.get({phoneNumber: objectTaskDetailsData.value.customerPhone})
      const customerExistsInClosedCase = await mainDB.closedCaseFollowingCustomers.get({phoneNumber: objectTaskDetailsData.value.customerPhone})
      if(customerExistsInPotential || customerExistsInHotHit || customerExistsInClosedCase) {
        customerPhoneValidateStatus.value = false
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: vm.$i18n.t('Error Message'),
            icon: 'AlertCircleIcon',
            variant: 'danger',
            html: vm.$i18n.t('Modules.Task.Message.You can not add this customer'),
          },
        })
      } else {
        if(objectTaskDetailsData.value.customerPhone == objectTaskDetailsData.value.workPlacePhone) {
          customerPhoneValidateStatus.value = false
          vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: vm.$i18n.t('Error Message'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              html: vm.$i18n.t('Modules.Task.Message.Please check cell phone and work phone'),
            },
          })
        } else {
          customerPhoneValidateStatus.value = true
          userDB.customerPotentials.put({
            id: objectTaskDetailsData.value.customer.id,
            syncId: vm.generateSyncId(),
            name: objectTaskDetailsData.value.customerName,
            phoneNumber: objectTaskDetailsData.value.customerPhone,
            state: objectTaskDetailsData.value.customerState,
            email: objectTaskDetailsData.value.email,
            gender: objectTaskDetailsData.value.customerGender,
            shopOwner: objectTaskDetailsData.value.shopOwner,
            status: objectTaskDetailsData.value.customerStatus,
            noteIds: objectTaskDetailsData.value.customer.notes.map((note) => note.id).join(","),
            createdAt: vm.getCurrentTimestampSeconds()
          });
          userDB.potentials.put({
            id: 'waiting:' + vm.formatPhoneNumberShort(objectTaskDetailsData.value.customerPhone),
            name: objectTaskDetailsData.value.customerName,
            phoneNumber: objectTaskDetailsData.value.customerPhone,
            stateLabel: await vm.getStateLabel(objectTaskDetailsData.value.customerState),
            email: objectTaskDetailsData.value.email,
            customer: {},
            nameSearch: objectTaskDetailsData.value.customerName.toLowerCase(),
            phoneNumberSearch: vm.formatPhoneNumberShort(objectTaskDetailsData.value.customerPhone),
            isWaiting: true,
            notes: [],
            lastCallTime: vm.getCurrentTimestampSeconds(),
            subStatus: 'other'
          })
          refetchAllData()
          taskSession.taskDetailsData = JSON.parse(JSON.stringify(objectTaskDetailsData.value))
          store.dispatch("task/update", taskSession);
        }
      }
    };
    const updateCustomer = async (syncStatus) => {
      if (objectTaskDetailsData.value.customer.type == "work_phone") {
        var customerUpdateData = {
          id: objectTaskDetailsData.value.customer.id,
          syncId: vm.generateSyncId(),
          name: objectTaskDetailsData.value.workPlaceName,
          email: objectTaskDetailsData.value.email,
        }
        if(objectTaskDetailsData.value.customerStatus != props.taskDetailsDataOrigin.customerStatus) customerUpdateData.status = objectTaskDetailsData.value.customerStatus
        userDB.customerUpdates.put(customerUpdateData);
      } else {
        var customerUpdateData = {
          id: objectTaskDetailsData.value.customer.id,
          syncId: vm.generateSyncId(),
          name: objectTaskDetailsData.value.customerName,
          email: objectTaskDetailsData.value.email,
          gender: objectTaskDetailsData.value.customerGender,
          shopOwner: objectTaskDetailsData.value.shopOwner
        }
        if(objectTaskDetailsData.value.customerStatus != props.taskDetailsDataOrigin.customerStatus) customerUpdateData.status = objectTaskDetailsData.value.customerStatus
        if(objectTaskDetailsData.value.customerState != props.taskDetailsDataOrigin.customerState) customerUpdateData.state = objectTaskDetailsData.value.customerState
        if(objectTaskDetailsData.value.workPlaceName != props.taskDetailsDataOrigin.workPlaceName) {
          customerUpdateData.workPlaceName = objectTaskDetailsData.value.workPlaceName
          customerUpdateData.workPlacePhone = objectTaskDetailsData.value.workPlacePhone
        }
        if(objectTaskDetailsData.value.workPlacePhone != props.taskDetailsDataOrigin.workPlacePhone) {
          const customerExistsInPotential = await mainDB.potentialFollowingCustomers.get({phoneNumber: objectTaskDetailsData.value.workPlacePhone})
          const customerExistsInHotHit = await mainDB.hotHitFollowingCustomers.get({phoneNumber: objectTaskDetailsData.value.workPlacePhone})
          const customerExistsInClosedCase = await mainDB.closedCaseFollowingCustomers.get({phoneNumber: objectTaskDetailsData.value.workPlacePhone})
          if(customerExistsInPotential || customerExistsInHotHit || customerExistsInClosedCase) {
            workPlacePhoneValidateStatus.value = false
            vm.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: vm.$i18n.t('Error Message'),
                icon: 'AlertCircleIcon',
                variant: 'danger',
                html: vm.$i18n.t('Modules.Task.Message.You can not change this work phone'),
              },
            })
          } else {
            customerUpdateData.workPlaceName = objectTaskDetailsData.value.workPlaceName
            customerUpdateData.workPlacePhone = objectTaskDetailsData.value.workPlacePhone
          }
        }
        userDB.customerUpdates.put(customerUpdateData);
      }
      if(objectTaskDetailsData.value.customerStatus != props.taskDetailsDataOrigin.customerStatus) {
        if(objectTaskDetailsData.value.customer.type == 'work_phone') {
          var newItem = {
            id: 'waiting:' + vm.formatPhoneNumberShort(objectTaskDetailsData.value.workPlacePhone),
            name: objectTaskDetailsData.value.workPlaceName,
            workPlaceName: objectTaskDetailsData.value.workPlaceName,
            phoneNumber: objectTaskDetailsData.value.workPlacePhone,
            stateLabel: await vm.getStateLabelByPhone(objectTaskDetailsData.value.workPlacePhone),
            email: objectTaskDetailsData.value.email,
            customer: objectTaskDetailsData.value.customer,
            nameSearch: objectTaskDetailsData.value.workPlaceName.toLowerCase(),
            phoneNumberSearch: vm.formatPhoneNumberShort(objectTaskDetailsData.value.workPlacePhone),
            isWaiting: true,
            notes: objectTaskDetailsData.value.notes,
            lastCallTime: objectTaskDetailsData.value.lastCallTime,
            subStatus: 'other'
          }
        } else {
          var newItem = {
            id: 'waiting:' + vm.formatPhoneNumberShort(objectTaskDetailsData.value.customerPhone),
            name: objectTaskDetailsData.value.customerName,
            customerName: objectTaskDetailsData.value.customerName,
            phoneNumber: objectTaskDetailsData.value.customerPhone,
            stateLabel: await vm.getStateLabelByPhone(objectTaskDetailsData.value.customerPhone),
            email: objectTaskDetailsData.value.email,
            customer: objectTaskDetailsData.value.customer,
            nameSearch: objectTaskDetailsData.value.customerName.toLowerCase(),
            phoneNumberSearch: vm.formatPhoneNumberShort(objectTaskDetailsData.value.customerPhone),
            isWaiting: true,
            notes: objectTaskDetailsData.value.notes,
            lastCallTime: objectTaskDetailsData.value.lastCallTime,
            subStatus: 'other'
          }
        }
        switch (objectTaskDetailsData.value.customerStatus) {
          case "potential":
            userDB.potentials.put(newItem)
            switch (props.tabChoosed) {
              case "cold_call":
                userDB.coldCalls.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "hothit":
                userDB.hotHits.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "denied":
                userDB.denieds.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "closed_case":
                userDB.closedCases.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
            }
            break;
          case "hothit":
            userDB.hotHits.put(newItem)
            switch (props.tabChoosed) {
              case "cold_call":
                userDB.coldCalls.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "potential":
                userDB.potentials.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "denied":
                userDB.denieds.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "closed_case":
                userDB.closedCases.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
            }
            break;
          case "denied":
            userDB.denieds.put(newItem)
            switch (props.tabChoosed) {
              case "cold_call":
                userDB.coldCalls.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "potential":
                userDB.potentials.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "hothit":
                userDB.hotHits.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "closed_case":
                userDB.closedCases.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
            }
            break;
          case "closed_case":
            userDB.closedCases.put(newItem)
            switch (props.tabChoosed) {
              case "cold_call":
                userDB.coldCalls.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "potential":
                userDB.potentials.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "hothit":
                userDB.hotHits.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
              case "denied":
                userDB.denieds.where("id").equals(objectTaskDetailsData.value.id).delete().then(() => {});
                break;
            }
            break;
        }
      }
      switch (props.tabChoosed) {
        case "cold_call":
          userDB.coldCalls.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          break;
        case "potential":
          userDB.potentials.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          break;
        case "hothit":
          userDB.hotHits.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          break;
        case "denied":
          userDB.denieds.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          break;
        case "closed_case":
          userDB.closedCases.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          break;
        case "assistant":
          break;
        case "call_log":
          break;
      }
      refetchAllData()
      if(syncStatus) context.emit("sync");
    };
    const backToTaskList = () => {
      context.emit('update:is-show-details', false)
      clearCurrentTime()
    }
    const autoSelectState = async (validationState, phoneNumber) => {
      if(validationState) {
        const stateId = await vm.getStateByPhone(phoneNumber)
        if(stateId != null) {
          objectTaskDetailsData.value.customerState = stateId
        }
      }
    }
    const refetchAllData = () => {
      switch (props.tabChoosed) {
        case "cold_call":
          context.emit('refetch-coldcalls')
          break;
        case "potential":
          context.emit('refetch-potentials')
          break;
        case "hothit":
          context.emit('refetch-hothits')
          break;
        case "denied":
          context.emit('refetch-denieds')
          break;
        case "closed_case":
          context.emit('refetch-closedcases')
          break;
        case "assistant":
          break;
        case "call_log":
          break;
      }
    }
    const updateCustomerNote = (noteData) => {
      if(noteData.customerNote) {
        userDB.customerNotes.put({
          syncId: vm.generateSyncId(),
          customerId: noteData.customerId,
          newPhoneNumber: noteData.newPhoneNumber ?? null,
          note: noteData.customerNote,
          createdAt: vm.getCurrentTimestampSeconds(),
        })
        objectTaskDetailsData.value.notes.unshift({
          note: noteData.customerNote,
          createdAt: vm.getCurrentTimestamp()
        })
        switch (props.tabChoosed) {
          case "cold_call":
            userDB.coldCalls.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
            break;
          case "potential":
            userDB.potentials.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
            break;
          case "hothit":
            userDB.hotHits.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
            break;
          case "denied":
            userDB.denieds.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
            break;
          case "closed_case":
            userDB.closedCases.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
            break;
          case "assistant":
            break;
          case "call_log":
            break;
        }
      }
      refetchAllData()
      context.emit("sync");
    }
    const updateCustomerCallHisttory = (status) => {
      objectTaskDetailsData.value.callHistories.unshift({
        status: status,
        createdAt: vm.getCurrentTimestamp()
      })
      objectTaskDetailsData.value.lastCallTime = vm.getCurrentTimestamp()
      switch (props.tabChoosed) {
        case "cold_call":
        userDB.coldCalls.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          if(!['no_answer', 'busy'].includes(status)) {
            userDB.coldCalls
            .where("id")
            .equals(objectTaskDetailsData.value.id)
            .delete()
            .then(() => {});
          } else {
            if(props.coldCallIsSortDirDesc == false) {
              if(props.coldCallSortBy != 'lastCallTime') context.emit("update:index-choosed", props.indexChoosed + 1);
            } else {
              context.emit("update:index-choosed", props.indexChoosed + 1);
            }
          }
          break;
        case "potential":
          userDB.potentials.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          break;
        case "hothit":
          userDB.hotHits.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          break;
        case "denied":
          userDB.denieds.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          break;
        case "closed_case":
          userDB.closedCases.update(objectTaskDetailsData.value.id, objectTaskDetailsData.value);
          break;
        case "assistant":
          break;
        case "call_log":
          break;
      }
    }

    return {
      //isCalling,
      callResult,
      refFormObserver,
      getValidationState,
      resetForm,
      onChange,
      onSave,
      onSubmit,
      handleCall,
      handleEndCall,
      chooseNextItem,
      choosePrevItem,
      chooseStatus,
      callResultStatus,
      addCustomer,
      updateCustomer,
      noteColumns,
      callHistoryColumns,
      taskDetailsTabActive,
      phoneStatusOptions,
      backToTaskList,
      editorOption,
      autoSelectState,
      objectTaskDetailsData,
      saveButtonStatus,
      customerPhoneValidateStatus,
      workPlacePhoneValidateStatus,
      switchPhoneType,
      refetchAllData,
      isAddCustomerNoteCollapseActive,
      updateCustomerNote,
      updateCustomerCallHisttory,
      potentialSubStatusItems,
      hotHitSubStatusItems,
      subStatusChoosed,
      subStatusValue,
      //Time
      currentTimeInterval,
      currentLocale,
      customerLocale,
      getCurrentTime,
      loadCurrentTime,
      clearCurrentTime,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/task.scss";
</style>
