<template>
  <section id="add-customer-note">
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col lg="10">
            <validation-provider
              #default="validationContext"
              rules="required"
            >
              <div class="form-label-group">
                <quill-editor
                  v-model="customerNote"
                  :options="editorOption"
                  style="height: 200px;"
                  :state="getValidationState(validationContext)"
                />
                <label for="label-textarea">{{ $t("note") }}</label>
              </div>
            </validation-provider>
          </b-col>
          <b-col lg="2">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="resetCustomerNoteForm"
                class="mt-0"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
              >
                {{ $t('Save') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </section>
</template>

<script>
import {
  BRow, BCol, BForm, BButton
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BForm, BButton,
    ValidationProvider, ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddCustomerNoteCollapseActive: {
      type: Boolean,
      required: true,
    },
    customerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      required,
    }
  },
  setup(props, context) {
    const vm = context.root
    const editorOption = ref({
      modules: {
        toolbar: false,
      },
      placeholder: vm.$i18n.t('note') + '...',
    })

    const customerNote = ref(null)

    const resetCustomerNote = () => {
      customerNote.value = null
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCustomerNote)

    const resetCustomerNoteForm = () => {
      resetForm()
      context.emit('update:is-add-customer-note-collapse-active', false)
    }

    watch(() => props.isAddCustomerNoteCollapseActive, () => {
      resetForm()
    });

    const onSubmit = () => {
      context.emit('update-customer-note', {customerId: props.customerId, customerNote: customerNote.value})
      resetCustomerNoteForm()
    }

    return {
      customerNote,
      editorOption,
      refFormObserver,
      getValidationState,
      resetForm,
      resetCustomerNoteForm,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-customer-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
