<template>
  <div>
    <!-- Toggler -->
    <b-link
      class="add-customer-toggle align-items-center justify-content-center"
      :class="isAddNewCustomerSidebarActive ? 'open': ''"
      @click="$emit('update:is-add-new-customer-sidebar-active', !isAddNewCustomerSidebarActive)"
    >
      <feather-icon
        icon="UserPlusIcon"
        size="15"
      />
    </b-link>
    <!-- /Toggler -->
    <b-sidebar
      id="add-new-customer-sidebar"
      :visible="isAddNewCustomerSidebarActive"
      bg-variant="white"
      sidebar-class="add-customer-sidebar"
      shadow
      no-header
      left
      @change="(val) => $emit('update:is-add-new-customer-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t("Modules.Customer.Label.Add Customer") }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
            v-on:change="resetFormStatus = false"
          >
            <!-- Phone Type -->
            <!-- <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.Phone type')"
              rules="required"
            >
              <b-form-group
                :label="$t('Modules.Customer.Label.Phone type')"
                label-for="phone-type"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="customerData.phoneType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="phoneTypeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="phone-type"
                  autocomplete="new-phone-type"
                />
              </b-form-group>
            </validation-provider> -->

            <!-- Customer Name -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.Customer name')"
              rules="required"
            >
              <b-form-group
                :label="$t('Modules.Customer.Label.Customer name')"
                label-for="customer_name"
              >
                <b-form-input
                  id="customer_name"
                  v-model="customerData.customerName"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  autocapitalize="off"
                  autocomplete="new-customer-name"
                  autocorrect="off"
                  spellcheck="false"
                />
              </b-form-group>
            </validation-provider>

            <!-- Phone Number -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.Phone number')"
              rules="required|regex:^[+]1 [(][0-9]{3}[)] [0-9]{3}[-][0-9]{4}]*$"
            >
              <b-form-group
                :label="$t('Modules.Customer.Label.Phone number')"
                label-for="customer_phone"
              >
              <b-form-input
                  id="customer_phone_number"
                  v-model="customerData.customerPhone"
                  autofocus
                  :state="getValidationState(validationContext) && customerPhoneValidateStatus"
                  trim
                  autocapitalize="off"
                  autocomplete="new-customer-phone-number"
                  autocorrect="off"
                  spellcheck="false"
                  v-mask="'+1 (###) ###-####'"
                  @change="autoSelectState(getValidationState(validationContext), customerData.customerPhone)"
                  @keyup="autoSelectState(getValidationState(validationContext), customerData.customerPhone)"
                />
              </b-form-group>
            </validation-provider>

            <!-- State -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.State')"
              rules="required"
            >
              <b-form-group
                :label="$t('Modules.Customer.Label.State')"
                label-for="customer_state"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="customerData.customerState"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="stateOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="customer_state"
                  autocomplete="new-customer-state"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
            </validation-provider>

            <!-- Customer Email -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.Email')"
              rules="email"
            >
              <b-form-group
                :label="$t('Modules.Customer.Label.Email')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="customerData.email"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  autocapitalize="off"
                  autocomplete="new-email"
                  autocorrect="off"
                  spellcheck="false"
                />
              </b-form-group>
            </validation-provider>

            <!-- Status -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.Status')"
              rules="required"
            >
              <b-form-group
                :label="$t('Modules.Customer.Label.Status')"
                label-for="status"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="customerData.customerStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="phoneStatusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
                  autocomplete="new-status"
                />
              </b-form-group>
            </validation-provider>

            <!-- Gender -->
            <validation-provider
              #default="validationContext"
              rules="required"
              :name="$t('Modules.Customer.Label.Gender')"
            >
              <b-form-group
                label-for="gender"
              >
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="customerData.customerGender"
                    name="female"
                    value="female"
                    class="custom-control-primary"
                    :state="genderValidationState(validationContext)"
                  >
                    {{ $t('Female') }}
                  </b-form-radio>
                  <b-form-radio
                    v-model="customerData.customerGender"
                    name="male"
                    value="male"
                    class="custom-control-primary"
                    :state="genderValidationState(validationContext)"
                  >
                    {{ $t('Male') }}
                  </b-form-radio>
                </div>
              </b-form-group>
            </validation-provider>

            <!-- Shop Owner -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.Shop owner')"
            >
              <b-form-group
                label-for="shop_owner"
                :state="getValidationState(validationContext)"
              >
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-model="customerData.shopOwner"
                    value="true"
                  >
                    {{ $t('Modules.Customer.Label.Shop owner') }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </validation-provider>

            <!-- Shop -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.Work place information')"
            >
              <b-form-group
                label-for="shop_owner"
                :state="getValidationState(validationContext)"
              >
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-model="customerData.isWorkPlace"
                    value="true"
                  >
                    {{ $t('Modules.Customer.Label.Work place information') }}
                  </b-form-checkbox>
                </div>
              </b-form-group>
            </validation-provider>

            <!-- Work Place -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.Work place')"
              v-if="customerData.phoneType == 'cell_phone' && customerData.isWorkPlace"
            >
              <b-form-group
                :label="$t('Modules.Customer.Label.Work place')"
                label-for="work_place_name"
              >
                <b-form-input
                  id="work_place_name"
                  v-model="customerData.workPlaceName"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  autocapitalize="off"
                  autocomplete="new-work-place-name"
                  autocorrect="off"
                  spellcheck="false"
                />
              </b-form-group>
            </validation-provider>

            <!-- Work Phone -->
            <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.Work phone')"
              rules="regex:^[+]1 [(][0-9]{3}[)] [0-9]{3}[-][0-9]{4}]*$"
              v-if="customerData.phoneType == 'cell_phone' && customerData.isWorkPlace"
            >
              <b-form-group
                :label="$t('Modules.Customer.Label.Work phone')"
                label-for="work_place_phone_number"
              >
              <b-form-input
                  id="work_place_phone_number"
                  v-model="customerData.workPlacePhone"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  autocapitalize="off"
                  autocomplete="new-work-place-phone-number"
                  autocorrect="off"
                  spellcheck="false"
                  v-mask="'+1 (###) ###-####'"
                />
              </b-form-group>
            </validation-provider>

            <!-- Work Place State -->
            <!-- <validation-provider
              #default="validationContext"
              :name="$t('Modules.Customer.Label.State')"
              v-if="customerData.phoneType == 'cell_phone'"
            >
              <b-form-group
                :label="$t('Modules.Customer.Label.State')"
                label-for="work_state"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="customerData.workPlaceState"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="stateOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="work_state"
                  autocomplete="new-work-state"
                />
              </b-form-group>
            </validation-provider> -->

            <!-- Form Actions -->
            <div class="d-flex mt-2 justify-content-sm-end">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="mr-2"
                @click="resetForm"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
              >
                {{ $t('Save') }}
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormRadio, BFormCheckbox, BLink
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mainDB } from "@/database/mainDB"
import { userDB } from "@/database/userDB"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BLink,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCustomerSidebarActive',
    event: 'update:is-add-new-customer-sidebar-active',
  },
  props: {
    isAddNewCustomerSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, context) {
    const vm = context.root
    const customerPhoneValidateStatus = ref(true)
    const resetFormStatus = ref(false)

    const phoneTypeOptions = [
      { label: vm.$i18n.t("work_phone"), value: "work_phone" },
      { label: vm.$i18n.t("cell_phone"), value: "cell_phone" },
    ];

    const phoneStatusOptions = [
      { label: vm.$i18n.t("potential"), value: "potential" },
      { label: vm.$i18n.t("hothit"), value: "hothit" },
    ];

    // States
    const stateOptions = ref([])
    mainDB.states
      .orderBy("stateName")
      .each((state) =>
        stateOptions.value.push({ label: state.stateLabel, value: state.id })
      );

    const blankCustomerData = {
      phoneType: 'cell_phone',
      customerName: null,
      customerPhone: null,
      customerState: null,
      workPlaceName: null,
      workPlacePhone: null,
      workPlaceState: null,
      email: null,
      customerGender: null,
      shopOwner: false,
      isWorkPlace: false,
      customerStatus: 'potential'
    }

    const customerData = ref(JSON.parse(JSON.stringify(blankCustomerData)))

    const resetCustomerData = () => {
      customerData.value = JSON.parse(JSON.stringify(blankCustomerData))
    }

    const onSubmit = async () => {
      resetFormStatus.value = true
      const customerExistsInPotential = await mainDB.potentialFollowingCustomers.get({phoneNumber: customerData.value.customerPhone})
      const customerExistsInHotHit = await mainDB.hotHitFollowingCustomers.get({phoneNumber: customerData.value.customerPhone})
      const customerExistsInClosedCase = await mainDB.closedCaseFollowingCustomers.get({phoneNumber: customerData.value.customerPhone})
      if(customerExistsInPotential || customerExistsInHotHit || customerExistsInClosedCase) {
        customerPhoneValidateStatus.value = false
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: vm.$i18n.t('Error Message'),
            icon: 'AlertCircleIcon',
            variant: 'danger',
            html: vm.$i18n.t('Modules.Task.Message.You can not add this customer'),
          },
        })
      } else {
        if(customerData.value.customerPhone == customerData.value.workPlacePhone) {
          customerPhoneValidateStatus.value = false
          vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: vm.$i18n.t('Error Message'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              html: vm.$i18n.t('Modules.Task.Message.Please check cell phone and work phone'),
            },
          })
        } else {
          customerPhoneValidateStatus.value = true
          userDB.customerInserts.put({
            syncId: vm.getCurrentTimestamp() + ':' + Math.random().toString(36).substring(2).toUpperCase(),
            phoneType: customerData.value.phoneType,
            name: customerData.value.customerName,
            phoneNumber: customerData.value.customerPhone,
            state: customerData.value.customerState,
            email: customerData.value.email,
            status: customerData.value.customerStatus,
            gender: customerData.value.customerGender,
            shopOwner: customerData.value.shopOwner,
            workPlaceName: customerData.value.workPlaceName,
            workPlacePhone: customerData.value.workPlacePhone,
            createdAt: Math.floor(Date.now() / 1000),
          });
          var customerObjectData = {
            id: 'waiting:' + vm.formatPhoneNumberShort(customerData.value.customerPhone),
            name: customerData.value.customerName,
            phoneNumber: customerData.value.customerPhone,
            stateLabel: await vm.getStateLabel(customerData.value.customerState),
            email: customerData.value.email,
            customer: {},
            nameSearch: customerData.value.customerName.toLowerCase(),
            phoneNumberSearch: vm.formatPhoneNumberShort(customerData.value.customerPhone),
            isWaiting: true
          }
          if(customerData.value.customerStatus == 'potential') {
            userDB.potentials.put(customerObjectData)
          } else {
            userDB.hotHits.put(customerObjectData)
          }
          vm.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: vm.$i18n.t('Success'),
              icon: 'CoffeeIcon',
              variant: 'success',
              html: vm.$i18n.t('Modules.Task.Message.Customer added successfully'),
            },
          })
          refetchAllData()
          resetForm()
        }
      }
      context.emit("sync");
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCustomerData)

    const autoSelectState = async (validationState, phoneNumber) => {
      if(validationState) {
        const stateId = await vm.getStateByPhone(phoneNumber)
        if(stateId != null) {
          customerData.value.customerState = stateId
        }
      }
    }

    const refetchAllData = () => {
      context.emit('refetch-coldcalls')
      context.emit('refetch-potentials')
      context.emit('refetch-hothits')
    }

    const genderValidationState = (validationContext) => {
      if(resetFormStatus.value) return true
      return getValidationState(validationContext)
    }

    return {
      phoneTypeOptions,
      phoneStatusOptions,
      stateOptions,
      resetFormStatus,

      customerData,
      onSubmit,
      autoSelectState,

      refFormObserver,
      getValidationState,
      genderValidationState,
      resetForm,
      customerPhoneValidateStatus,
      refetchAllData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-customer-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
