<template>
  <section id="task" class="task-section">
    <add-customer
      :is-add-new-customer-sidebar-active.sync="isAddNewCustomerSidebarActive"
      @refetch-coldcalls="refetchColdCalls"
      @refetch-potentials="refetchPotentials"
      @refetch-hothits="refetchHotHits"
      @sync="sync"
    />
    <b-row v-show="firstSync">
      <b-col lg="12">
        <b-card class="mb-0">
          <b-card-text>
            <h5 class="text-center mb-1">{{ $t('Loading data') }}...</h5>
            <div class="d-flex justify-content-center">
              <b-spinner label="Loading..." variant="primary" />
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-show="!isShowDetails && !firstSync">
      <b-col lg="12">
        <b-card class="mb-0">
          <b-card-text>
            <b-tabs fill pills :nav-class="'nav-pill-' + getPageTypeVariant(taskSession.tabChoosed)">
              <b-tab
                :title="'COLD CALL (' + coldCallTotal + ')'"
                :active="taskSession.tabChoosed == 'cold_call'"
                @click="chooseTab('cold_call')"
              >
                <b-row>
                  <b-col md="12" lg="6"></b-col>
                  <b-col md="12" lg="6">
                    <b-pagination
                      v-model="coldCallCurrentPage"
                      :total-rows="coldCallTotal"
                      :per-page="coldCallPerPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 float-right"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
                <b-table
                  ref="refColdCallTable"
                  class="position-relative mt-1"
                  :items="fetchColdCalls"
                  responsive
                  :fields="coldCallColumns"
                  sticky-header="646px"
                  hover
                  show-empty
                  :empty-text="$t('No matching records found')"
                  @row-clicked="rowClicked"
                  :sort-by.sync="coldCallSortBy"
                  :sort-desc.sync="coldCallIsSortDirDesc"
                >
                  <!-- Column: Name -->
                  <template #cell(name)="data">
                    <div class="text-nowrap">
                      {{
                        (data.item.customer.type == "cell_phone"
                          ? data.item.customerGender
                            ? $t(
                                "Modules.Customer.Label." +
                                data.item.customerGender
                              )
                            : ""
                          : "") + (
                            data.item.customer.type == "cell_phone" ? data.item.customerName : data.item.workPlaceName
                          )
                      }}
                    </div>
                  </template>
                  <!-- Column: Note -->
                  <template #cell(note)="data">
                    <div v-html="lastNote(data.item.customer.notes)">
                      <!-- <truncate action-class="btn-show-more-less" :clamp="$t('Show more')" :length="150" :less="$t('Show less')" type="html" :text="lastNote(data.item.customer.notes)"></truncate> -->
                    </div>
                  </template>
                  <!-- Column: Last Call Time -->
                  <template #cell(lastCallTime)="data">
                    <div class="text-nowrap" v-html="formatTimeZone(data.item.lastCallTime)">
                    </div>
                  </template>
                </b-table>
              </b-tab>
              <b-tab
                :title="'POTENTIAL (' + potentialTotal + ')'"
                :active="taskSession.tabChoosed == 'potential'"
                @click="chooseTab('potential')"
              >
                <b-row>
                  <b-col md="12" lg="7">
                    <div class="filter-sub-status">
                      <sub-status
                        :enable-status-items.sync="potentialSubStatusItems"
                        :status-choosed.sync="potentialSubStatusChoosed"
                        :status-value.sync="potentialSubStatusChoosed"
                        :multiple-choice=true
                      />
                    </div>
                  </b-col>
                  <b-col md="12" lg="5">
                    <b-pagination
                      v-model="potentialCurrentPage"
                      :total-rows="potentialTotal"
                      :per-page="potentialPerPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 float-right"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
                <b-table
                  ref="refPotentialTable"
                  class="position-relative mt-1"
                  :items="fetchPotentials"
                  responsive
                  :fields="potentialColumns"
                  sticky-header="646px"
                  hover
                  show-empty
                  :empty-text="$t('No matching records found')"
                  @row-clicked="rowClicked"
                  :tbody-tr-class="rowClass"
                  :sort-by.sync="potentialSortBy"
                  :sort-desc.sync="potentialIsSortDirDesc"
                >
                  <!-- Column: Name -->
                  <template #cell(name)="data">
                    <div class="text-nowrap">
                      {{
                        (data.item.customer.type == "cell_phone"
                          ? data.item.customerGender
                            ? $t(
                                "Modules.Customer.Label." +
                                data.item.customerGender
                              )
                            : ""
                          : "") + (
                            data.item.customer.type == "cell_phone" ? data.item.customerName : data.item.workPlaceName
                          )
                      }}
                    </div>
                  </template>
                  <!-- Column: Note -->
                  <template #cell(note)="data">
                    <div v-html="data.item.isWaiting ? $t('Updating data. Please waiting') : ( data.item.customer.notes ? lastNote(data.item.customer.notes) :  '' )">
                      <!-- <truncate action-class="btn-show-more-less" :clamp="$t('Show more')" :length="150" :less="$t('Show less')" type="html" :text="lastNote(data.item.customer.notes)"></truncate> -->
                    </div>
                  </template>
                  <!-- Column: Sub Status -->
                  <template #cell(subStatus)="data">
                    <b-badge
                      :variant="subStatusItems[data.item.subStatus].variant"
                    >
                      {{ subStatusItems[data.item.subStatus].label }}
                    </b-badge>
                  </template>
                  <!-- Column: Call Result -->
                  <template #cell(callResult)="data">
                    <b-badge
                      :variant="callResultItems[data.item.callResult].variant"
                      v-if="data.item.callResult"
                    >
                      {{ callResultItems[data.item.callResult].label }}
                    </b-badge>
                  </template>
                  <!-- Column: Last Call Time -->
                  <template #cell(lastCallTime)="data">
                    <div class="text-nowrap" v-html="formatTimeZone(data.item.lastCallTime)">
                    </div>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item @click="deleteItem('potential', data.item)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">{{
                          $t("Delete")
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-tab>
              <b-tab
                :title="'HOT HIT (' + hotHitTotal + ')'"
                :active="taskSession.tabChoosed == 'hothit'"
                @click="chooseTab('hothit')"
              >
                <b-row>
                  <b-col md="12" lg="6">
                    <div class="filter-sub-status">
                      <sub-status
                        :enable-status-items.sync="hotHitSubStatusItems"
                        :status-choosed.sync="hotHitSubStatusChoosed"
                        :status-value.sync="hotHitSubStatusChoosed"
                        :multiple-choice=true
                      />
                    </div>
                  </b-col>
                  <b-col md="12" lg="6">
                    <b-pagination
                      v-model="hotHitCurrentPage"
                      :total-rows="hotHitTotal"
                      :per-page="hotHitPerPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 float-right"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
                <b-table
                  ref="refHotHitTable"
                  class="position-relative mt-1"
                  :items="fetchHotHits"
                  responsive
                  :fields="hotHitColumns"
                  sticky-header="646px"
                  hover
                  show-empty
                  :empty-text="$t('No matching records found')"
                  @row-clicked="rowClicked"
                  :tbody-tr-class="rowClass"
                  :sort-by.sync="hotHitSortBy"
                  :sort-desc.sync="hotHitIsSortDirDesc"
                >
                  <!-- Column: Name -->
                  <template #cell(name)="data">
                    <div class="text-nowrap">
                      {{
                        (data.item.customer.type == "cell_phone"
                          ? data.item.customerGender
                            ? $t(
                                "Modules.Customer.Label." +
                                data.item.customerGender
                              )
                            : ""
                          : "") + (
                            data.item.customer.type == "cell_phone" ? data.item.customerName : data.item.workPlaceName
                          )
                      }}
                    </div>
                  </template>
                  <!-- Column: Note -->
                  <template #cell(note)="data">
                    <div v-html="data.item.isWaiting ? $t('Updating data. Please waiting') : ( data.item.customer.notes ? lastNote(data.item.customer.notes) :  '' )">
                      <!-- <truncate action-class="btn-show-more-less" :clamp="$t('Show more')" :length="150" :less="$t('Show less')" type="html" :text="lastNote(data.item.customer.notes)"></truncate> -->
                    </div>
                  </template>
                  <!-- Column: Sub Status -->
                  <template #cell(subStatus)="data">
                    <b-badge
                      :variant="subStatusItems[data.item.subStatus].variant"
                    >
                      {{ subStatusItems[data.item.subStatus].label }}
                    </b-badge>
                  </template>
                  <!-- Column: Last Call Time -->
                  <template #cell(lastCallTime)="data">
                    <div class="text-nowrap" v-html="formatTimeZone(data.item.lastCallTime)">
                    </div>
                  </template>
                  <!-- Column: Call Result -->
                  <template #cell(callResult)="data">
                    <b-badge
                      :variant="callResultItems[data.item.callResult].variant"
                      v-if="data.item.callResult"
                    >
                      {{ callResultItems[data.item.callResult].label }}
                    </b-badge>
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item @click="deleteItem('hothit', data.item)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">{{
                          $t("Delete")
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-tab>
              <b-tab
                :title="'DENIED (' + deniedTotal + ')'"
                :active="taskSession.tabChoosed == 'denied'"
                @click="chooseTab('denied')"
              >
                <b-row>
                  <b-col md="12" lg="6"></b-col>
                  <b-col md="12" lg="6">
                    <b-pagination
                      v-model="deniedCurrentPage"
                      :total-rows="deniedTotal"
                      :per-page="deniedPerPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 float-right"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
                <b-table
                  ref="refDeniedTable"
                  class="position-relative mt-1"
                  :items="fetchDenieds"
                  responsive
                  :fields="deniedColumns"
                  sticky-header="646px"
                  hover
                  show-empty
                  :empty-text="$t('No matching records found')"
                  @row-clicked="rowClicked"
                  :tbody-tr-class="rowClass"
                  :sort-by.sync="deniedSortBy"
                  :sort-desc.sync="deniedIsSortDirDesc"
                >
                  <!-- Column: Name -->
                  <template #cell(name)="data">
                    <div class="text-nowrap">
                      {{
                        (data.item.customer.type == "cell_phone"
                          ? data.item.customerGender
                            ? $t(
                                "Modules.Customer.Label." +
                                data.item.customerGender
                              )
                            : ""
                          : "") + (
                            data.item.customer.type == "cell_phone" ? data.item.customerName : data.item.workPlaceName
                          )
                      }}
                    </div>
                  </template>
                  <!-- Column: Note -->
                  <template #cell(note)="data">
                    <div v-html="data.item.isWaiting ? $t('Updating data. Please waiting') : ( data.item.customer.notes ? lastNote(data.item.customer.notes) :  '' )">
                      <!-- <truncate action-class="btn-show-more-less" :clamp="$t('Show more')" :length="150" :less="$t('Show less')" type="html" :text="lastNote(data.item.customer.notes)"></truncate> -->
                    </div>
                  </template>
                  <!-- Column: Last Call Time -->
                  <template #cell(lastCallTime)="data">
                    <div class="text-nowrap" v-html="formatTimeZone(data.item.lastCallTime)">
                    </div>
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item @click="deleteItem('denied', data.item)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">{{
                          $t("Delete")
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-tab>
              <b-tab
                :title="'CLOSED CASE (' + closedCaseTotal + ')'"
                :active="taskSession.tabChoosed == 'closed_case'"
                @click="chooseTab('closed_case')"
              >
                <b-row>
                  <b-col md="12" lg="6"></b-col>
                  <b-col md="12" lg="6">
                    <b-pagination
                      v-model="closedCaseCurrentPage"
                      :total-rows="closedCaseTotal"
                      :per-page="closedCasePerPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 float-right"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
                <b-table
                  ref="refClosedCaseTable"
                  class="position-relative mt-1"
                  :items="fetchClosedCases"
                  responsive
                  :fields="closedCaseColumns"
                  sticky-header="646px"
                  hover
                  show-empty
                  :empty-text="$t('No matching records found')"
                  @row-clicked="rowClicked"
                  :tbody-tr-class="rowClass"
                  :sort-by.sync="closedCaseSortBy"
                  :sort-desc.sync="closedCaseIsSortDirDesc"
                >
                  <!-- Column: Name -->
                  <template #cell(name)="data">
                    <div class="text-nowrap">
                      {{
                        (data.item.customer.type == "cell_phone"
                          ? data.item.customerGender
                            ? $t(
                                "Modules.Customer.Label." +
                                data.item.customerGender
                              )
                            : ""
                          : "") + (
                            data.item.customer.type == "cell_phone" ? data.item.customerName : data.item.workPlaceName
                          )
                      }}
                    </div>
                  </template>
                  <!-- Column: Note -->
                  <template #cell(note)="data">
                    <div v-html="data.item.isWaiting ? $t('Updating data. Please waiting') : ( data.item.customer.notes ? lastNote(data.item.customer.notes) :  '' )">
                      <!-- <truncate action-class="btn-show-more-less" :clamp="$t('Show more')" :length="150" :less="$t('Show less')" type="html" :text="lastNote(data.item.customer.notes)"></truncate> -->
                    </div>
                  </template>
                  <!-- Column: Last Call Time -->
                  <template #cell(lastCallTime)="data">
                    <div class="text-nowrap" v-html="formatTimeZone(data.item.lastCallTime)">
                    </div>
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item @click="deleteItem('closed_case', data.item)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">{{
                          $t("Delete")
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-tab>
              <b-tab
                title="ASSISTANT"
                :active="taskSession.tabChoosed == 'assistant'"
                @click="chooseTab('assistant')"
                v-if="false"
              >
                ASSISTANT
              </b-tab>
              <b-tab
                title="CALL LOG"
                :active="taskSession.tabChoosed == 'call_log'"
                @click="chooseTab('call_log')"
                v-if="false"
              >
                CALL LOG
              </b-tab>
            </b-tabs>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <task-details
      :tab-choosed.sync="tabChoosed"
      :index-choosed.sync="indexChoosed"
      :task-details-data.sync="taskDetailsData"
      :task-details-data-origin.sync="taskDetailsDataOrigin"
      :is-show-details.sync="isShowDetails"
      :is-calling.sync="isCalling"
      :cold-call-sort-by.sync="coldCallSortBy"
      :cold-call-is-sort-dir-desc.sync="coldCallIsSortDirDesc"
      :state-options="stateOptions"
      @load-details-by-index-choosed="loadDetailsByIndexChoosed"
      @refetch-coldcalls="refetchColdCalls"
      @refetch-potentials="refetchPotentials"
      @refetch-hothits="refetchHotHits"
      @refetch-denieds="refetchDenieds"
      @refetch-closedcases="refetchClosedCases"
      @sync="sync"
      v-if="isShowDetails  && !firstSync"
    />
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BTabs,
  BTab,
  BTable,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import { ref, watch } from "@vue/composition-api";
import store from "@/store";
import TaskRepository from "@/modules/task/repository/taskRepository";
import { mainDB } from "@/database/mainDB";
import { userDB } from "@/database/userDB";
import useColdCalls from "./useColdCalls";
import usePotentials from "./usePotentials";
import useHotHits from "./useHotHits";
import useDenieds from "./useDenieds";
import useClosedCases from "./useClosedCases";
import taskDetails from "./taskDetails.vue";
import addCustomer from "./addCustomer.vue";
import truncate from 'vue-truncate-collapsed';
import subStatus from "./subStatus.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BTabs,
    BTab,
    BTable,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    taskDetails,
    addCustomer,
    truncate,
    subStatus,
  },
  setup(props, context) {
    /******************************** */
    /** Main */
    /******************************** */
    const vm = context.root;
    const taskSession = store.state.task;
    const taskSync = store.state.taskSync;
    const tabChoosed = ref(taskSession.tabChoosed);
    const indexChoosed = ref(taskSession.indexChoosed);
    const isShowDetails = ref(taskSession.isShowDetails);
    const auth = store.state.auth;
    const firstSync = ref(true)
    const isAddNewCustomerSidebarActive = ref(false);
    const isCalling = ref(false)
    const staffSaveData = ref(taskSync.staffId.value)

    const subStatusItems = ref({
      all: {label: vm.$i18n.t('StatusLabel.All'), variant: 'primary'},
      approach: {label: vm.$i18n.t('StatusLabel.Approach'), variant: 'info'},
      potential: {label: vm.$i18n.t('StatusLabel.Potential'), variant: 'success'},
      do_not_forget: {label: vm.$i18n.t('StatusLabel.Do Not Forget'), variant: 'danger'},
      call_back: {label: vm.$i18n.t('StatusLabel.Call Back'), variant: 'warning'},
      not_enough_info: {label: vm.$i18n.t('StatusLabel.Not enough information'), variant: 'secondary'},
      other: {label: vm.$i18n.t('StatusLabel.Other'), variant: 'dark'},
    })

    const callResultItems =  ref({
      no_answer: {label: 'No Answer', variant: 'primary'},
      answer: {label: 'Answer', variant: 'success'},
      hang_up: {label: 'Hang Up', variant: 'info'},
      wrong_number: {label: 'Wrong Number', variant: 'danger'},
      busy: {label: 'Busy', variant: 'warning'},
      no_need: {label: 'No Need', variant: 'dark'},
    })

    // States
    const stateOptions = ref([])
    mainDB.states
      .orderBy("stateName")
      .each((state) =>
        stateOptions.value.push({ label: state.stateLabel, value: state.id })
      );
    /**
     * Sync data from server
     * @param {Boolean} enableLoading
     */
     const sync = async (disableReload) => {
      // Call Results
      var callResultParams = [];
      const callResultsData = await userDB.callResults.orderBy('createdAt').toArray();
      callResultsData.forEach((item, index) => {
        callResultParams.push({
          syncId: item.syncId,
          customerId: item.id,
          taskId: item.taskId,
          newPhoneNumber: item.customerPhone,
          note: item.note,
          status: item.status,
          createdAt: item.createdAt,
          type: item.type,
          isKeepData: item.isKeepData
        })
      })
      // Customer Updates
      var customerUpdateParams = [];
      const customerUpdatesData = await userDB.customerUpdates.toArray();
      customerUpdatesData.forEach((item, index) => {
        customerUpdateParams.push({
          syncId: item.syncId,
          customerId: item.id,
          name: item.name,
          email: item.email,
          status: item.status,
          state: item.state,
          gender: item.gender,
          shopOwner: item.shopOwner,
          workPlaceName: item.workPlaceName,
          workPlacePhone: item.workPlacePhone
        })
      })
      // Customer Potentials
      var customerPotentialParams = [];
      const customerPotentialsData = await userDB.customerPotentials.toArray();
      customerPotentialsData.forEach((item, index) => {
        customerPotentialParams.push({
          syncId: item.syncId,
          customerId: item.id,
          name: item.name,
          phoneNumber: item.phoneNumber,
          email: item.email,
          status: item.status,
          state: item.state,
          gender: item.gender,
          shopOwner: item.shopOwner,
          note: item.note,
          noteIds: item.noteIds,
          createdAt: item.createdAt
        })
      })
      // Customer Insert
      var customerInsertParams = await userDB.customerInserts.toArray()
      // Customer Notes
      var customerNotesParams = await userDB.customerNotes.toArray()
      // Potential Sub Status Update
      var potentialSubStatusParams = await userDB.potentialSubStatus.toArray()
      var hotHitSubStatusParams = await userDB.hotHitSubStatus.toArray()
      // Tasks Removed
      var tasksRemovedParams = await userDB.tasksRemoved.toArray()

      const lastPotentialSynced = await mainDB.potentialFollowingCustomers.orderBy('updatedAt').last()
      const lastHotHitSynced = await mainDB.hotHitFollowingCustomers.orderBy('updatedAt').last()
      const lastClosedCaseSynced = await mainDB.closedCaseFollowingCustomers.orderBy('updatedAt').last()
      TaskRepository
      .sync({
        callResults: callResultParams,
        customerUpdates: customerUpdateParams,
        customerPotentials: customerPotentialParams,
        customerInserts: customerInsertParams,
        customerNotes: customerNotesParams,
        potentialSubStatus: potentialSubStatusParams,
        hotHitSubStatus: hotHitSubStatusParams,
        tasksRemoved: tasksRemovedParams,
        lastPotentialSyncedTime: typeof lastPotentialSynced != 'undefined' ? lastPotentialSynced.updatedAt : 0,
        lastHotHitSyncedTime: typeof lastHotHitSynced != 'undefined' ? lastHotHitSynced.updatedAt : 0,
        lastClosedCaseSyncedTime: typeof lastClosedCaseSynced != 'undefined' ? lastClosedCaseSynced.updatedAt : 0,
        staffLoadData: taskSync.staffId.value ?? null,
        staffSaveData: staffSaveData.value ?? null
        //page: taskSession.customerSyncPage
      })
      .then((response) => {
        staffSaveData.value = taskSync.staffId.value
        // Cold Calls
        let coldCallsInsert = []
        let coldCalls = response.data.data.coldCalls
        coldCalls.forEach((item, index) => {
          if(item.customer) coldCallsInsert.push(generateTaskData(item))
        })
        userDB.coldCalls.where('id').notEqual('').delete()
        userDB.coldCalls.bulkPut(coldCallsInsert)
        // Potentials
        let potentialsInsert = []
        let potentials = response.data.data.potentials
        potentials.forEach((item, index) => {
          if(item.customer) potentialsInsert.push(generateTaskData(item))
        })
        userDB.potentials.where('id').notEqual('').delete()
        userDB.potentials.bulkPut(potentialsInsert)
        // Hot Hits
        let hotHitsInsert = []
        let hotHits = response.data.data.hotHits
        hotHits.forEach((item, index) => {
          if(item.customer) hotHitsInsert.push(generateTaskData(item))
        })
        userDB.hotHits.where('id').notEqual('').delete()
        userDB.hotHits.bulkPut(hotHitsInsert)
        // Denieds
        let deniedsInsert = []
        let denieds = response.data.data.denieds
        denieds.forEach((item, index) => {
          if(item.customer) deniedsInsert.push(generateTaskData(item))
        })
        userDB.denieds.where('id').notEqual('').delete()
        userDB.denieds.bulkPut(deniedsInsert)
        // Close Cases
        let closedCasesInsert = []
        let closedCases = response.data.data.closedCases
        closedCases.forEach((item, index) => {
          if(item.customer) closedCasesInsert.push(generateTaskData(item))
        })
        userDB.closedCases.where('id').notEqual('').delete()
        userDB.closedCases.bulkPut(closedCasesInsert)
        // Task Sync
        let taskSyncIds = response.data.data.taskSyncIds;
        userDB.callResults.where('syncId').anyOf(taskSyncIds).delete();
        userDB.customerUpdates.where('syncId').anyOf(taskSyncIds).delete();
        userDB.customerPotentials.where('syncId').anyOf(taskSyncIds).delete();
        userDB.customerInserts.where('syncId').anyOf(taskSyncIds).delete();
        userDB.customerNotes.where('syncId').anyOf(taskSyncIds).delete();
        userDB.potentialSubStatus.where('syncId').anyOf(taskSyncIds).delete();
        userDB.hotHitSubStatus.where('syncId').anyOf(taskSyncIds).delete();
        userDB.tasksRemoved.where('syncId').anyOf(taskSyncIds).delete();
        // Sync Following Customers
        // Potential
        let arrNewFollowingPotentials = []
        let newFollowingPotentials = response.data.data.newFollowingPotentials
        newFollowingPotentials.forEach((item, index) => {
          arrNewFollowingPotentials.push({
            phoneNumber: vm.formatPhoneNumber(item.phoneNumber),
            updatedAt: item.updatedAt
          })
        })
        mainDB.potentialFollowingCustomers.bulkPut(arrNewFollowingPotentials)

        let arrRemovedFollowingPotentials = []
        let removedFollowingPotentials = response.data.data.removedFollowingPotentials
        removedFollowingPotentials.forEach((item, index) => {
          arrRemovedFollowingPotentials.push(vm.formatPhoneNumber(item.phoneNumber))
        })
        mainDB.potentialFollowingCustomers.where('phoneNumber').anyOf(arrRemovedFollowingPotentials).delete()

        // Hot Hit
        let arrNewFollowingHotHits = []
        let newFollowingHotHits = response.data.data.newFollowingHotHits
        newFollowingHotHits.forEach((item, index) => {
          arrNewFollowingHotHits.push({
            phoneNumber: vm.formatPhoneNumber(item.phoneNumber),
            updatedAt: item.updatedAt
          })
        })
        mainDB.hotHitFollowingCustomers.bulkPut(arrNewFollowingHotHits)

        let arrRemovedFollowingHotHits = []
        let removedFollowingHotHits = response.data.data.removedFollowingHotHits
        removedFollowingHotHits.forEach((item, index) => {
          arrRemovedFollowingHotHits.push(vm.formatPhoneNumber(item.phoneNumber))
        })
        mainDB.hotHitFollowingCustomers.where('phoneNumber').anyOf(arrRemovedFollowingHotHits).delete()

        // Close Case
        let arrNewFollowingClosedCases = []
        let newFollowingClosedCases = response.data.data.newFollowingClosedCases
        newFollowingClosedCases.forEach((item, index) => {
          arrNewFollowingClosedCases.push({
            phoneNumber: vm.formatPhoneNumber(item.phoneNumber),
            updatedAt: item.updatedAt
          })
        })
        mainDB.closedCaseFollowingCustomers.bulkPut(arrNewFollowingClosedCases)

        let arrRemovedFollowingClosedCases = []
        let removedFollowingClosedCases = response.data.data.removedFollowingClosedCases
        removedFollowingClosedCases.forEach((item, index) => {
          arrRemovedFollowingClosedCases.push(vm.formatPhoneNumber(item.phoneNumber))
        })
        mainDB.closedCaseFollowingCustomers.where('phoneNumber').anyOf(arrRemovedFollowingClosedCases).delete()

        // Sync customers
        // let arrCustomers = [];
        // let customers = response.data.data.customers.data
        // let customerMeta = response.data.data.customers.meta
        // customers.forEach((item, index) => {
        //   arrCustomers.push({
        //     id: item.id,
        //     name: item.name,
        //     phoneNumber: vm.formatPhoneNumber(item.phoneNumber),
        //     email: item.email,
        //     type: item.type,
        //     status: item.status,
        //     state: item.state,
        //     gender: item.gender,
        //     deletedAt: item.deletedAt
        //   })
        // })
        // mainDB.customers.bulkPut(arrCustomers)
        // let localTotalCustomers = mainDB.customers.count()
        // if(localTotalCustomers < customerMeta.total) {
        //   taskSession.customerSyncPage = customerMeta.current_page + 1
        // } else {
        //   taskSession.customerSyncPage = customerMeta.current_page + 1
        //   if(taskSession.customerSyncPage > process.env.VUE_APP_SYNC_CUSTOMER_PAGE) {
        //     taskSession.customerSyncPage = 1
        //   }
        // }
        // store.dispatch("task/update", taskSession)

        firstSync.value = false
        if(!disableReload) {
          refetchColdCalls()
          refetchPotentials()
          refetchHotHits()
          refetchDenieds()
          refetchClosedCases()
        }

      }).catch(error => {
        clearAsyncInterval(setIntervalAsyncIndex)
      })
    }

    sync();

    // Interval
    const asyncIntervals = ref([]);

    const runAsyncInterval = async (cb, interval, intervalIndex) => {
      await cb();
      if (asyncIntervals.value[intervalIndex]) {
        setTimeout(() => runAsyncInterval(cb, interval, intervalIndex), interval);
      }
    };

    const setAsyncInterval = (cb, interval) => {
      if (cb && typeof cb === "function") {
        const intervalIndex = asyncIntervals.value.length;
        asyncIntervals.value.push(true);
        runAsyncInterval(cb, interval, intervalIndex);
        return intervalIndex;
      } else {
        throw new Error('Callback must be a function');
      }
    };

    const clearAsyncInterval = (intervalIndex) => {
      if (asyncIntervals.value[intervalIndex]) {
        asyncIntervals.value[intervalIndex] = false;
      }
    };

    const setIntervalAsyncIndex = setAsyncInterval(async () => {
      if(auth.isLoggedIn && !firstSync.value) {
        await sync();
      }
    }, process.env.VUE_APP_SYNC_TIME*60*1000);

    const rowClicked = (item, index, event) => {
      if(!item.isWaiting) {
        vm.autoShowDetailLoading();
        indexChoosed.value = index;
        loadDetailsByIndexChoosed()
      }
    };

    const chooseTab = (tab) => {
      tabChoosed.value = tab;
      taskSession.tabChoosed = tab;
      store.dispatch("task/update", taskSession);
    };

    const lastNote = (notes) => {
      const note = notes.find(item => item.note != '' && item.note != null );
      if(typeof note != 'undefined') {
        return vm.truncateString(note.note.replace(/(<([^>]+)>)/gi, ""), 150);
      } else {
        return '';
      }
    }

    const rowClass = (item, type) => {
      const colorClass = 'is-waiting'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.isWaiting) { return colorClass }
    }

    const generateTaskData = (item) => {
      if(item.customer.type == 'work_phone') {
        return {
          id: item.id,
          name: item.customer.name,
          phoneNumber: vm.formatPhoneNumber(item.customer.phoneNumber),
          stateCode: item.customer.state.stateCode,
          stateLabel: item.customer.state.stateLabel,
          email: item.customer.email,
          customer: item.customer,
          nameSearch: vm.removeVietnameseTones(item.customer.name.toLowerCase()) + ' ' + item.customer.name.toLowerCase(),
          phoneNumberSearch: item.customer.phoneNumber,
          customerName: null,
          customerPhone: null,
          customerState: null,
          customerStatus:  item.customer.status,
          customerGender: null,
          shopOwner: false,
          workPlaceName: item.customer.name,
          workPlacePhone: vm.formatPhoneNumber(item.customer.phoneNumber),
          notes: item.customer.notes,
          callHistories: item.customer.callHistories,
          lastCallTime: item.customer.callHistories.length > 0 ? item.customer.callHistories[0].createdAt : (item.customer.notes.length > 0 ? item.customer.notes[0].createdAt : 0),
          callResult: item.customer.callHistories.length > 0 ? item.customer.callHistories[0].status : null,
          isKeepData: item.isKeepData ?? false,
          subStatus: 'not_enough_info'
        }
      } else {
        return {
          id: item.id,
          name: item.customer.name,
          phoneNumber: vm.formatPhoneNumber(item.customer.phoneNumber),
          stateCode: item.customer.state.stateCode,
          stateLabel: item.customer.state.stateLabel,
          email: item.customer.email,
          customer: item.customer,
          nameSearch: vm.removeVietnameseTones(item.customer.name.toLowerCase()) + ' ' + item.customer.name.toLowerCase(),
          phoneNumberSearch: item.customer.phoneNumber,
          customerName: item.customer.name,
          customerPhone: vm.formatPhoneNumber(item.customer.phoneNumber),
          customerState: item.customer.state.id,
          customerStatus:  item.customer.status,
          customerGender: item.customer.gender,
          shopOwner: item.customer.shopOwner,
          workPlaceName: item.customer.workplaceInformation ? (item.customer.workplaceInformation.workplaceName ? item.customer.workplaceInformation.workplaceName : (item.customer.workplace ? item.customer.workplace.name : null)) : (item.customer.workplace ? item.customer.workplace.name : null),
          workPlacePhone: item.customer.workplace ? vm.formatPhoneNumber(item.customer.workplace.phoneNumber) : null,
          notes: item.customer.notes,
          callHistories: item.customer.callHistories,
          lastCallTime: item.customer.callHistories.length > 0 ? item.customer.callHistories[0].createdAt : (item.customer.notes.length > 0 ? item.customer.notes[0].createdAt : 0),
          callResult: item.customer.callHistories.length > 0 ? item.customer.callHistories[0].status : null,
          isKeepData: item.isKeepData ?? false,
          subStatus: item.subStatus ? item.subStatus : 'other'
        }
      }
    }
    const deleteItem = async (tab, item) => {
      var customerName = (item.customer.type == "cell_phone" ? (item.customerGender ? vm.$i18n.t("Modules.Customer.Label." + item.customerGender) : "") : "") + (item.customer.type == "cell_phone" ? item.customerName : item.workPlaceName)
      if(await vm.showDeleteConfirmDialog(vm.$i18n.t('Delete customer') + ': ' + customerName + ' ?')) {
        var deleteItemValue = {
          id: item.id,
          syncId: vm.generateSyncId(),
          createdAt: vm.getCurrentTimestampSeconds()
        }
        switch (tab) {
          case "potential":
            deleteItemValue.status = 'potential'
            userDB.potentials.where("id").equals(item.id).delete().then(() => {});
            refetchPotentials()
            break;
          case "hothit":
            deleteItemValue.status = 'hothit'
            userDB.hotHits.where("id").equals(item.id).delete().then(() => {});
            refetchHotHits()
            break;
          case "denied":
            deleteItemValue.status = 'denied'
            userDB.denieds.where("id").equals(item.id).delete().then(() => {});
            refetchDenieds()
            break;
          case "closed_case":
            deleteItemValue.status = 'closed_case'
            userDB.closedCases.where("id").equals(item.id).delete().then(() => {});
            refetchClosedCases()
            break;
        }
        userDB.tasksRemoved.add(deleteItemValue)
        sync();
      }
    }
    /******************************** */

    /******************************** */
    /** Cold Calls */
    /******************************** */
    const {
      coldCallSortBy,
      coldCallIsSortDirDesc,
      refColdCallTable,
      coldCallTotal,
      coldCallColumns,
      coldCallCurrentPage,
      coldCallPerPage,
      fetchColdCalls,
      refetchColdCalls,
    } = useColdCalls();
    /******************************** */

    /******************************** */
    /** Potentials */
    /******************************** */
    const {
      potentialSortBy,
      potentialIsSortDirDesc,
      refPotentialTable,
      potentialTotal,
      potentialColumns,
      potentialSubStatusItems,
      potentialSubStatusChoosed,
      potentialCurrentPage,
      potentialPerPage,
      fetchPotentials,
      refetchPotentials,
    } = usePotentials();
    /******************************** */

    /******************************** */
    /** Hot Hit */
    /******************************** */
    const {
      hotHitSortBy,
      hotHitIsSortDirDesc,
      refHotHitTable,
      hotHitTotal,
      hotHitColumns,
      hotHitSubStatusItems,
      hotHitSubStatusChoosed,
      hotHitCurrentPage,
      hotHitPerPage,
      fetchHotHits,
      refetchHotHits,
    } = useHotHits();
    /******************************** */

    /******************************** */
    /** Denied */
    /******************************** */
    const {
      deniedSortBy,
      deniedIsSortDirDesc,
      refDeniedTable,
      deniedTotal,
      deniedColumns,
      deniedCurrentPage,
      deniedPerPage,
      fetchDenieds,
      refetchDenieds,
    } = useDenieds();
    /******************************** */

    /******************************** */
    /** Close Case */
    /******************************** */
    const {
      closedCaseSortBy,
      closedCaseIsSortDirDesc,
      refClosedCaseTable,
      closedCaseTotal,
      closedCaseColumns,
      closedCaseCurrentPage,
      closedCasePerPage,
      fetchClosedCases,
      refetchClosedCases,
    } = useClosedCases();
    /******************************** */

    /******************************** */
    /** Details */
    /******************************** */
    const taskDetailsData = ref(taskSession.taskDetailsData);
    const taskDetailsDataOrigin = ref(JSON.parse(JSON.stringify(taskSession.taskDetailsData)));
    const showDetails = (item) => {
      if(item.customer.type == 'work_phone') {
        item.customerName = taskSession.taskDetailsData.customerName ? taskSession.taskDetailsData.customerName : item.customerName
        item.customerPhone = taskSession.taskDetailsData.customerPhone ? vm.formatPhoneNumber(taskSession.taskDetailsData.customerPhone) : item.customerPhone
        item.customerState = taskSession.taskDetailsData.customerState ? taskSession.taskDetailsData.customerState : item.customerState
        item.customerStatus = taskSession.taskDetailsData.customerStatus ? taskSession.taskDetailsData.customerStatus : item.customerStatus
        item.customerGender = taskSession.taskDetailsData.customerGender ? taskSession.taskDetailsData.customerGender : item.customerGender
      }
      taskDetailsData.value = item
      taskDetailsDataOrigin.value = JSON.parse(JSON.stringify(item))
      taskSession.taskDetailsData = JSON.parse(JSON.stringify(item))
      store.dispatch("task/update", taskSession);
      isCalling.value = false
      isShowDetails.value = true
    }
    const loadDetailsByIndexChoosed = async () => {
      let items = []
      let totalItems = ref(0)
      switch (tabChoosed.value) {
        case "cold_call":
          items = await fetchColdCalls()
          totalItems = items.length
          //refetchColdCalls()
          break;
        case "potential":
          items = await fetchPotentials()
          totalItems = items.length
          //refetchPotentials()
          break;
        case "hothit":
          items = await fetchHotHits()
          totalItems = items.length
          //refetchHotHits()
          break;
        case "denied":
          items = await fetchDenieds()
          totalItems = items.length
          //refetchDenieds()
          break;
        case "closed_case":
          items = await fetchClosedCases()
          totalItems = items.length
          //refetchClosedCases()
          break;
        case "assistant":
          break;
        case "call_log":
          break;
      }
      if(indexChoosed.value == totalItems) indexChoosed.value = 0
      if(indexChoosed.value == -1) indexChoosed.value = totalItems - 1
      if(indexChoosed.value != null) showDetails(items[indexChoosed.value])
      taskSession.indexChoosed = indexChoosed.value
      store.dispatch('task/update', taskSession)
    }
    /******************************** */

    /******************************** */
    /** Watch */
    /******************************** */
    if(indexChoosed.value != null) loadDetailsByIndexChoosed()

    watch(isShowDetails, () => {
      taskSession.isShowDetails = isShowDetails.value
      store.dispatch('task/update', taskSession)
      if(!isShowDetails.value) {
        indexChoosed.value = null
        taskDetailsData.value = {}
        taskDetailsDataOrigin.value = {}
        taskSession.taskDetailsData = {}
        store.dispatch("task/update", taskSession);
      }
    }, { immediate: true });

    watch(vm.$isSearch, async () => {
      if(vm.$isSearch.value) {
        tabChoosed.value = taskSession.tabChoosed
        indexChoosed.value = taskSession.indexChoosed
        showDetails(taskSession.taskDetailsData)
        vm.$isSearch.value = false
      }
    }, { immediate: true });

    watch(taskSync, () => {
      isShowDetails.value = false
      vm.showLoading()
      sync()
    });
    /******************************** */

    return {
      stateOptions,
      firstSync,
      sync,
      taskSession,
      tabChoosed,
      chooseTab,
      lastNote,
      rowClass,
      generateTaskData,
      deleteItem,
      subStatusItems,
      callResultItems,
      isCalling,
      staffSaveData,
      // Cold Calls
      coldCallSortBy,
      coldCallIsSortDirDesc,
      refColdCallTable,
      coldCallTotal,
      coldCallColumns,
      coldCallCurrentPage,
      coldCallPerPage,
      fetchColdCalls,
      refetchColdCalls,
      // Potentials
      potentialSortBy,
      potentialIsSortDirDesc,
      refPotentialTable,
      potentialTotal,
      potentialColumns,
      fetchPotentials,
      refetchPotentials,
      potentialSubStatusItems,
      potentialSubStatusChoosed,
      potentialCurrentPage,
      potentialPerPage,
      // Hot Hits
      hotHitIsSortDirDesc,
      hotHitSortBy,
      refHotHitTable,
      hotHitTotal,
      hotHitColumns,
      hotHitCurrentPage,
      hotHitPerPage,
      fetchHotHits,
      refetchHotHits,
      hotHitSubStatusItems,
      hotHitSubStatusChoosed,
      // Denieds
      deniedSortBy,
      deniedIsSortDirDesc,
      refDeniedTable,
      deniedTotal,
      deniedColumns,
      deniedCurrentPage,
      deniedPerPage,
      fetchDenieds,
      refetchDenieds,
      // Close Cases
      closedCaseSortBy,
      closedCaseIsSortDirDesc,
      refClosedCaseTable,
      closedCaseTotal,
      closedCaseColumns,
      closedCaseCurrentPage,
      closedCasePerPage,
      fetchClosedCases,
      refetchClosedCases,
      // Details
      indexChoosed,
      isShowDetails,
      rowClicked,
      showDetails,
      taskDetailsData,
      taskDetailsDataOrigin,
      loadDetailsByIndexChoosed,
      // Add Customer
      isAddNewCustomerSidebarActive
    };
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/task.scss";
.b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -1px;
}
</style>
