import axios from 'axios'

export default class TaskRepository {
  static sync(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/tasks/sync', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}